import React from 'react';
import { ProductCarouselItem } from './ProductCarouselItem';
import ProductListCarouselProps from './ProductCarousel.props';
import ProductCarouselContainer from './ProductCarouselContainer';

// eslint-disable-next-line @typescript-eslint/ban-types
const ProductCarousel = <H extends {}, B extends {}, F extends {}>({
  items,
  paginationTheme,
  center,
  Header,
  Body,
  Footer,
}: ProductListCarouselProps<H, B, F>) => {
  return (
    <ProductCarouselContainer paginationTheme={paginationTheme} center={center}>
      {items.map((item, index) => (
        <ProductCarouselItem
          key={index}
          Header={Header}
          Body={Body}
          Footer={Footer}
          {...item}
        />
      ))}
    </ProductCarouselContainer>
  );
};

export default ProductCarousel;
