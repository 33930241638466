import React from 'react';
import SectionCover from '@vcc-www/page-section/SectionCover';
import { getParams } from '@vcc-www/page-section';
import SharedCarModelCarousel from '@vcc-www/car-model-carousel';
import Props from './ProductListCarousel.props';
import { Footer } from './Footer';
import { Title } from './Title';

const ProductListCarousel: React.FC<Props> = ({
  content: { title, link1, link2, disclaimer, ...content },
  ...params
}) => {
  return (
    <SectionCover {...getParams(params)} extend={{ overflow: 'hidden' }}>
      <Title title={title} />
      <SharedCarModelCarousel
        {...content}
        background={params.background}
        center={params.center}
        showMy23ConfigLink={params.showMy23ConfigLink}
        shouldHidePrice={params.shouldHidePrice ?? false}
      />
      <Footer links={[link1, link2].filter(Boolean)} disclaimer={disclaimer} />
    </SectionCover>
  );
};

export default ProductListCarousel;
