import React from 'react';
import { ExtendCSS, Block, Flex } from 'vcc-ui';
import {
  SpringCarouselPane,
  SpringCarouselProvider,
  SpringCarouselDots,
  SpringCarouselPagination,
} from '@vcc-www/spring-carousel';
import GridHelper from '@vcc-www/grid-helper';
import { useTracker } from '@volvo-cars/tracking';
import ProductListCarouselProps from './ProductCarousel.props';

const Carousel: React.FC<
  React.PropsWithChildren<Omit<ProductListCarouselProps, 'items'>>
> = (props) => {
  const track = useTracker({}, { mode: 'both', ga3: { eventAction: 'swipe' } });
  const { paginationTheme, children, center } = props;

  return (
    <div className="overflow-hidden">
      <SpringCarouselProvider
        onChange={(event) => {
          if (event.type === 'swipe-pane') {
            track.interaction({
              eventAction: 'carousel|swipe',
              eventLabel: event.previous < event.current ? 'right' : 'left',
            });
          }
        }}
      >
        <Block extend={rootCSS}>
          <GridHelper size={12}>
            <Block extend={carouselWrapperCSS}>
              <SpringCarouselPane
                useRole
                alignment="left-cover"
                itemWidth={{
                  default: 0.8,
                  onlyM: 0.4,
                  fromL: 1 / 4,
                }}
                itemSpacing={{
                  default: 16,
                  fromM: 24,
                }}
                center={center}
              >
                {children}
              </SpringCarouselPane>
            </Block>
            <Flex extend={carouselControlsWrapper}>
              <SpringCarouselDots extend={dotsCSS} />
              <SpringCarouselPagination
                extend={arrowsCSS}
                color={paginationTheme}
              />
            </Flex>
          </GridHelper>
        </Block>
      </SpringCarouselProvider>
    </div>
  );
};

export default Carousel;

const rootCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  padding: `0 ${baselineGrid * 3}px`,
  untilM: {
    margin: `0 -${baselineGrid * 3}px`,
  },
});

const carouselWrapperCSS: ExtendCSS = {
  position: 'relative',
  margin: '0 auto',
  fromL: {
    overflow: 'hidden',
  },
};

const carouselControlsWrapper: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginTop: baselineGrid * 2,
  alignItems: 'flex-end',
  untilL: {
    marginTop: 0,
    alignItems: 'center',
  },
});

const dotsCSS: ExtendCSS = {
  fromL: {
    display: 'none',
  },
};

const arrowsCSS: ExtendCSS = {
  untilL: {
    display: 'none',
  },
};
