// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @volvo-cars/css/no-custom-class */
import React from 'react';
import { Block, ExtendCSS, Text, Flex } from 'vcc-ui';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import { Link } from '@vcc-www/buttons';
import Disclaimer from '@vcc-www/disclaimer';
import PricingText from '@vcc-www/pricing-text';
import DisplayPrice from '@vcc-www/display-price';
import { AvailableOnline } from '@vcc-www/car-badges';
import Head from 'next/head';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { useTracker } from '@volvo-cars/tracking';
import type {
  ImageField,
  LinkField,
} from '@vcc-www/content-management-jss-client';
import { CarPrice, CarPriceVariantType } from '@vcc-package/car-price';

export interface ItemHeaderProps {
  index: number;
  link1: LinkField;
  categoryTitle?: string;
  name: string;
  rechargeType?: string;
  pricingText?: string;
  startingPrice?: string;
  pricingSuffix?: string;
  startingPriceCash?: string;
  startingPriceSubscription?: string;
  image?: ImageField;
  isAvailableOnline?: boolean;
  slug?: string;
  showApiPrice: boolean;
  priceVariant: CarPriceVariantType;
  shouldHidePrice?: boolean;
}

export interface ItemFooterProps {
  index: number;
  link1: LinkField;
  link2?: LinkField;
  useLink3?: boolean;
  disclaimer?: string;
  my23ConfigLink?: LinkField;
  showMy23ConfigLink?: boolean;
}

export const ItemHeader: React.FC<React.PropsWithChildren<ItemHeaderProps>> = ({
  image,
  isAvailableOnline,
  index,
  link1,
  categoryTitle,
  name,
  rechargeType,
  startingPrice,
  startingPriceCash,
  startingPriceSubscription,
  pricingSuffix,
  pricingText,
  slug,
  showApiPrice,
  priceVariant,
  shouldHidePrice,
}) => {
  const track = useTracker({}, { mode: 'both', ga3: { eventAction: 'image' } });
  const { locale } = useCurrentMarketSite();
  const imageObjectSchema = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'ImageObject',
        '@id': image?.src,
        url: image?.src,
        contentUrl: image?.src,
        caption: image?.alt,
        inLanguage: locale,
        width: 640,
        height: 480,
      },
    ],
  };
  return link1 ? (
    <Flex
      as="a"
      href={link1.href}
      aria-label={name}
      tabIndex={0}
      extend={clickableContainerCSS}
      data-autoid={`productListCarouselItem-${index}`}
      draggable="false"
      onClick={(event: React.MouseEvent) => {
        if (!event.defaultPrevented) {
          track.interaction({
            eventLabel: `${index} | ${link1.text} | ${link1.href}`,
            eventAction: 'image|click',
          });
        }
      }}
    >
      <Flex extend={infoWrapperCSS}>
        <Text
          variant="bates"
          subStyle="emphasis"
          extend={categoryCSS}
          className="item-category"
          data-autoid="productListCarouselItem:category"
        >
          {categoryTitle && categoryTitle.toUpperCase()}
        </Text>
        <Block as="h3" extend={titleCSS}>
          <Text
            as="span"
            variant="columbus"
            subStyle="emphasis"
            extend={modelNameCSS}
            className="item-name"
            data-autoid="productListCarouselItem:modelName"
          >
            {name}
          </Text>
          {rechargeType && (
            <Text
              as="span"
              extend={rechargeTypeCSS}
              variant="columbus"
              className="item-name-suffix"
              data-autoid="productListCarouselItem:rechargeType"
            >
              {rechargeType}
            </Text>
          )}
        </Block>
        {!shouldHidePrice ? (
          <Block extend={priceWrapperCSS} className="item-price">
            {showApiPrice ? (
              <CarPrice
                modelSlug={slug}
                variant={priceVariant}
                layout="COMPACT"
              />
            ) : (
              <DisplayPrice
                type="subscriptioncash"
                price={[startingPriceSubscription, startingPriceCash]}
                msrp={!isAvailableOnline}
                data-autoid="productListCarouselItem:price"
                fallback={
                  <PricingText
                    prefix={pricingText}
                    suffix={pricingSuffix}
                    variant="bates"
                    priceSubStyle="standard"
                    extend={{ color: 'currentColor' }}
                    priceColor="foreground.primary"
                    data-autoid="productListCarouselItem:price"
                  >
                    {startingPrice}
                  </PricingText>
                }
              />
            )}
          </Block>
        ) : null}
      </Flex>
      <Flex extend={imageWrapperCSS}>
        {image?.src && (
          <>
            {imageObjectSchema && (
              <Head>
                <script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(imageObjectSchema),
                  }}
                />
              </Head>
            )}
            <Image_DEPRECATED
              objectFit="cover"
              src={image.src}
              alt={image.alt}
              loading="lazy"
              aspectRatio={[4, 3]}
              className="item-image"
              aria-hidden
            />
          </>
        )}
        {isAvailableOnline && (
          <AvailableOnline>
            {(Badge: JSX.Element) => (
              <Block extend={badgeContainerCSS}>{Badge}</Block>
            )}
          </AvailableOnline>
        )}
      </Flex>
    </Flex>
  ) : null;
};

export const ItemFooter: React.FC<React.PropsWithChildren<ItemFooterProps>> = ({
  link1,
  link2,
  useLink3,
  index,
  disclaimer,
  my23ConfigLink,
  showMy23ConfigLink,
}) => (
  <Block extend={linkOuterWrapperCSS}>
    <Flex
      extend={linkInnerWrapperCSS}
      className="product-list-carousel-item-links"
    >
      {!useLink3 &&
        (showMy23ConfigLink && my23ConfigLink?.href && my23ConfigLink?.text ? (
          <Link
            href={my23ConfigLink.href}
            target={my23ConfigLink.target}
            draggable="false"
            data-autoid="productListCarouselItem:my23ConfigLink"
            aria-label={my23ConfigLink.title}
            trackEventLabel={`${index} | ${my23ConfigLink.text} | ${my23ConfigLink.href}`}
            extend={linkCSS(1)}
            trackEventAction="link|click"
            trackGA3={{ eventAction: 'click' }}
          >
            {my23ConfigLink.text}
          </Link>
        ) : (
          <>
            {link1 && (
              <Link
                href={link1.href}
                target={link1.target}
                draggable="false"
                data-autoid="productListCarouselItem:link1"
                aria-label={link1.title}
                trackEventLabel={`${index} | ${link1.text} | ${link1.href}`}
                extend={linkCSS(1)}
                trackEventAction="link|click"
                trackGA3={{ eventAction: 'click' }}
              >
                {link1.text}
              </Link>
            )}
            {link2?.href && link2?.text && (
              <Link
                href={link2.href}
                target={link2.target}
                draggable="false"
                data-autoid="productListCarouselItem:link2"
                aria-label={link2.title}
                trackEventLabel={`${index} | ${link2.text} | ${link2.href}`}
                extend={linkCSS(2)}
                trackEventAction="link|click"
                trackGA3={{ eventAction: 'click' }}
              >
                {link2.text}
              </Link>
            )}
          </>
        ))}
      {!!disclaimer && <Disclaimer extend={disclaimerCSS} text={disclaimer} />}
    </Flex>
  </Block>
);

const clickableContainerCSS: ExtendCSS = ({ theme: { color } }) => ({
  textDecoration: 'none',
  flex: '1 1 auto',
  '& .item-image': {
    pointerEvents: 'none',
    transition: 'transform 300ms',
    transform: 'scale(1.01)',
  },
  '&:focus-visible': {
    outlineStyle: 'none',
    border: '2px solid',
    padding: '2px',
    transition: 'all 0.1s ease-out',
  },
  ':hover': {
    '& .item-image': {
      transform: 'scale(1.08)',
    },
    '& .item-category': {
      color: color.foreground.action,
    },
    '& .item-name': {
      color: color.foreground.action,
    },
    '& .item-name-suffix': {
      color: color.foreground.action,
    },
    '& .item-price *': {
      color: color.foreground.action,
    },
  },
});

const infoWrapperCSS: ExtendCSS = {
  flex: '1 1 auto',
};

const imageWrapperCSS: ExtendCSS = ({ theme: { color, baselineGrid } }) => ({
  margin: `${baselineGrid * 2}px 0`,
  background: color.background.secondary,
  position: 'relative',
  overflow: 'hidden',
  transition: 'box-shadow 300ms',
});

const badgeContainerCSS: ExtendCSS = ({ theme: { baselineSubGrid } }) => ({
  position: 'absolute',
  top: baselineSubGrid * 3,
  left: baselineSubGrid * 3,
  width: `calc(100% - ${baselineSubGrid * 6}px)`,
});

const categoryCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.secondary,
});

const titleCSS: ExtendCSS = ({ theme: { baselineSubGrid } }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: `0 0 ${baselineSubGrid}px 0`,
});

const modelNameCSS: ExtendCSS = {
  marginRight: 5,
};

const rechargeTypeCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.secondary,
});

const priceWrapperCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.secondary,
});

const linkOuterWrapperCSS: ExtendCSS = {
  minHeight: 'var(--product-list-carousel-links-height)',
};

const linkInnerWrapperCSS: ExtendCSS = ({ theme: { baselineSubGrid } }) => ({
  flex: '1 1 auto',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginLeft: -(baselineSubGrid * 3),
  marginRight: -(baselineSubGrid * 3),
  '& > *': {
    marginLeft: baselineSubGrid * 3,
    marginRight: baselineSubGrid * 3,
  },
});

const linkCSS = (position: number): ExtendCSS => ({
  alignSelf: 'flex-start',
  width: 'calc(50% - 24px)',
  textAlign: position === 1 ? 'left' : 'right',
  '&>a': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:nth-child(1)>span': {
      marginRight: '5px',
    },
  },
});

const disclaimerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginLeft: 0,
  marginTop: baselineGrid * 3,
  fromM: {
    textAlign: 'left',
    alignItems: 'center',
  },
  onlyM: {
    marginBottom: baselineGrid * 4,
  },
});
