import React, { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import throttle from 'lodash/throttle';
import ProductCarousel from '@vcc-www/product-carousel';
import { ItemFooter, ItemHeader } from './CarouselItem';
import type CarCategory from '@vcc-www/content-types/CarCategory.props';
import { getImage } from './getImage';
import { CarPriceProvider, CarPriceVariantType } from '@vcc-package/car-price';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { DeployEnv } from '@vcc-package/offers-utils/utils/get-env/get-env.types';

export type CarModelCarouselProps = Readonly<{
  categories?: readonly CarCategory[];
  priceText?: string;
  priceSuffix?: string;
  background?: 'black' | 'white' | 'light' | 'dark';
  center?: boolean;
  showMy23ConfigLink?: boolean;
  priceVariant?: CarPriceVariantType;
  showApiPrice?: boolean;
  shouldHidePrice?: boolean;
}>;

const CarModelCarousel = ({
  categories = [],
  background,
  center,
  showMy23ConfigLink,
  showApiPrice = false,
  priceVariant = 'MONTHLY_FROM_PURCHASE_FROM',
  shouldHidePrice,
}: PropsWithChildren<CarModelCarouselProps>) => {
  const { siteSlug, isAuthoringUrl } = useVolvoCarsUrl();
  const items = useMemo(() => {
    let counter = 0;
    return categories.flatMap(({ title, carModels }) =>
      carModels.map(
        ({
          name,
          rechargeType,
          pricingText,
          pricingSuffix,
          startingPrice,
          startingPriceCash,
          startingPriceSubscription,
          link1,
          link2,
          images,
          isAvailableOnline,
          pno3,
          modelYear,
          driveLine,
          my23ConfigLink,
          slug,
        }) => {
          const itemIndex = counter++;
          return {
            title,
            headerProps: {
              index: itemIndex,
              link1,
              categoryTitle: title,
              name,
              rechargeType,
              pricingText,
              pricingSuffix,
              startingPrice,
              startingPriceCash,
              startingPriceSubscription,
              image: getImage(images)?.image,
              isAvailableOnline,
              slug,
              showApiPrice,
              priceVariant,
              shouldHidePrice,
            },
            bodyProps: {},
            footerProps: {
              index: itemIndex,
              link1,
              link2,
              disclaimer: getImage(images)?.disclaimer,
              my23ConfigLink,
              showMy23ConfigLink,
            },
            trackingData: {
              carModelName: name,
              carModelId: pno3,
              carModelYear: modelYear,
              productClass: title,
              driveLine,
            },
          };
        },
      ),
    );
  }, [
    categories,
    showApiPrice,
    priceVariant,
    showMy23ConfigLink,
    shouldHidePrice,
  ]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeHandler = throttle(() => {
      if (!wrapperRef.current) return;

      const linkWrapperNodes = wrapperRef.current.querySelectorAll(
        '.product-list-carousel-item-links',
      );
      const minHeight = Array.from(linkWrapperNodes)
        .map((element) => element.clientHeight)
        .reduce((prev, cur) => Math.max(prev, cur), 0);

      wrapperRef.current.style.setProperty(
        '--product-list-carousel-links-height',
        `${minHeight}px`,
      );
    }, 50);

    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return (
    <div ref={wrapperRef} className="contents">
      <CarPriceProvider
        siteSlug={siteSlug ?? ''}
        clientName="shared-component"
        deployEnv={(process.env.NEXT_PUBLIC_DEPLOY_ENV as DeployEnv) ?? 'prod'}
        contentSource={isAuthoringUrl ? 'preview' : undefined}
      >
        <ProductCarousel
          // @ts-ignore Check if this is correct value to track for ga4
          items={items}
          paginationTheme={background}
          center={center}
          Header={ItemHeader}
          Footer={ItemFooter}
        />
      </CarPriceProvider>
    </div>
  );
};

export default CarModelCarousel;
