import Disclaimer from '@vcc-www/disclaimer';
import GridHelper from '@vcc-www/grid-helper';
import type { LinkEntry } from '@volvo-cars/content-management-client';
import React from 'react';
import { ExtendCSS } from 'vcc-ui';

type FooterProps = {
  links?: LinkEntry[];
  disclaimer?: string;
};

export const Footer = ({ disclaimer }: FooterProps) => {
  return (
    <GridHelper size={12}>
      {disclaimer && <Disclaimer extend={disclaimerCSS} text={disclaimer} />}
    </GridHelper>
  );
};

const disclaimerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginTop: baselineGrid * 4,
  padding: `0 ${baselineGrid * 3}px`,
  onlyS: {
    padding: 0,
  },
});
