import React from 'react';
import { ExtendCSS, Flex } from 'vcc-ui';
import { TrackingProvider } from '@volvo-cars/tracking';
import { ProductCarouselItemProps } from './ProductCarousel.props';

// eslint-disable-next-line @typescript-eslint/ban-types
export function ProductCarouselItem<H extends {}, B extends {}, F extends {}>({
  trackingData,
  Header,
  Body,
  Footer,
  headerProps,
  bodyProps,
  footerProps,
}: ProductCarouselItemProps<H, B, F>) {
  return (
    <TrackingProvider {...trackingData}>
      <Flex extend={rootCSS} draggable="false">
        {Header && <Header {...headerProps} />}
        {Body && <Body {...bodyProps} />}
        {Footer && <Footer {...footerProps} />}
      </Flex>
    </TrackingProvider>
  );
}

const rootCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  height: '100%',
  paddingTop: 2,
  paddingBottom: `${baselineGrid * 2}px`,
});
