import GridHelper from '@vcc-www/grid-helper';
import React from 'react';
import { ExtendCSS, Text } from 'vcc-ui';

export const Title = ({ title }: { title?: string }) => (
  <GridHelper size={12}>
    <Text
      as="h2"
      variant="ootah"
      subStyle="emphasis"
      extend={titleCSS}
      data-autoid="productListCarousel:title"
    >
      {title}
    </Text>
  </GridHelper>
);

const titleCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  textAlign: 'center',
  marginBottom: baselineGrid * 6,
  untilM: {
    marginBottom: baselineGrid * 4,
  },
});
