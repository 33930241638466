import type { ImageEntry } from '@volvo-cars/content-management-client';

type CarImage = Readonly<{
  type: string;
  image?: ImageEntry;
  disclaimer?: string;
}>;

export const getImage = (images: readonly CarImage[] = []) =>
  images.find(
    ({ type }) => type === 'product-list-mobile' || type === 'product-list',
  );
